import React from 'react';
import { TableCell } from '@material-ui/core';
import { connect } from 'react-redux';
import { currencyToSymbol } from 'utils/currencies';

class PriceSumCell extends React.Component {
  render() {
    const { dishSizes, size, currency } = this.props;

    const { price } = dishSizes.find(dishSize => dishSize.size === size['@id']);

    return (
      <TableCell>{price !== null && `${price.toFixed(2)} ${currencyToSymbol(
        currency
      )}`}</TableCell>
    );
  }
}

export default connect(state => ({
  currency: state.Brands.brand.multinational.currency,
}))(PriceSumCell);
