import React, { useEffect, useState } from 'react';
import AdminTable from 'layouts/AdminTable';
import Datetime from 'react-datetime';
import { connect } from 'react-redux';
import roles, {
  ROLE_CREATE_OVERPRODUCTION,
  ROLE_EDIT_OVERPRODUCTION,
  ROLE_REMOVE_OVERPRODUCTION,
  ROLE_SHOW_SUB_BRAND,
} from '../../helpers/roles';
import Moment from 'moment';
import { get, post, put, remove } from '../../helpers/apiHelpers';
import { withToast } from 'material-ui-toast-redux';
import { isDatepickerValidDay } from 'helpers/dateHelpers';
import { NavLink } from 'react-router-dom';
//styles
import {
  combineStyles,
  getNumberFromString,
  isGranted,
  sortByKey,
  sortByNestedKey,
  sortByGroupedBrandPosition,
  translateNamesForExport,
} from 'helpers/helpers';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle';
//components
import ReactTable from 'react-table';
import SweetAlert from 'react-bootstrap-sweetalert';

import ChooseFromMenuPlanningModal from './ChooseFromMenuPlanningModal';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import AccessDenied from 'components/Security/AccessDenied';
import { DialogLoader } from 'components/DialogLoader';
import SelectInput from 'components/FormSelect/SelectInput';
import CustomInput from 'components/CustomInput/CustomInput';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import { AddCircle, CheckBox, RemoveCircle } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown';
import moment from 'moment';
import axios from 'helpers/gastro';

import isEmpty from 'lodash/isEmpty';
import TOAST_DURATIONS from 'helpers/toastDurations';

const DATE_FORMAT = 'YYYY-MM-DD';

const Overproduction = ({
  classes,
  userBrands,
  openToast,
  selectedBrand,
  currency,
}) => {
  const { t } = useTranslation();
  const [overProductions, setOverProductions] = useState([]);
  const [date, setDate] = useState(new Date());
  const selectInBrands = userBrands.map(userBrand => ({
    value: userBrand['@id'],
    label: userBrand.name,
  }));

  const [mealTypesFromConfig, setMealTypesFromConfig] = useState([]);
  const [menuPlanner, setMenuPlanners] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [subBrands, setSubBrands] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [alert, setAlert] = useState(null);
  const [priceCache, setPriceCache] = useState({});
  const defaultPageSize = 10;
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pages, setPages] = useState(1);
  const [currentTotalItems, setCurrentTotalItems] = useState(0);
  const [exportQuery, setExportQuery] = useState({});
  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    if (selectInBrands.length) {
      // setPageSize(defaultPageSize)
      setIsFetching(true);
      Promise.all([
        fetchMenuPlanner(),
        isGranted(ROLE_SHOW_SUB_BRAND) && fetchSubBrands(),
        fetchMealTypesFromConfig(),
        getTotalItems(pageSize),
        fetchOverProductionsOnFetchData({}),
      ]).then(() => {
        setIsFetching(false);
      });
    } else {
      // setPageSize(defaultPageSize)
      setOverProductions([]);
    }
  }, [selectInBrands?.value, date]);

  const fetchSubBrands = async () => {
    const response = await get('/sub-brands', {
      // selectInBrands: selectInBrands.length ? selectInBrands.map(brand => brand.value).filter(val => val !== '*') : []
      pagination: false,
      selectInBrands: userBrands.map(userBrand => userBrand['@id']),
    });

    setSubBrands(response['hydra:member']);

    return response;
  };

  const getPriceForDishSize = async (dishSize, brandIri) => {
    if (!priceCache.hasOwnProperty(dishSize)) {
      const { price } = await get(dishSize, {
        properties: ['price'],
        selectInBrands: [brandIri],
      });

      let newPriceCache = { ...priceCache };
      newPriceCache[dishSize] = price;

      setPriceCache(newPriceCache);
      return newPriceCache[dishSize];
    }

    return priceCache[dishSize];
  };

  const getTotalItems = async pageSize => {
    const query = {
      'date[]': new Moment(date).format(DATE_FORMAT),
      'date[_operator]': 'eq',
      selectInBrands: selectInBrands.length
        ? selectInBrands.map(brand => brand.value).filter(val => val !== '*')
        : [],
    };

    let { 'hydra:totalItems': totalItems } = await get('/overproductions', {
      ...query,
      partial: false,
      'properties[]': '_',
    });
    let pages = Math.floor(totalItems / (pageSize || defaultPageSize));

    if (pages !== totalItems / pageSize) {
      pages++;
    }

    setCurrentTotalItems(totalItems);

    setPages(pages);
  };

  const fetchOverProductionsOnFetchData = ({ requestData, rowToBeSaved }) => {
    setIsTableLoading(true);
    const query = {
      itemsPerPage: requestData?.pageSize ?? pageSize,
      page: (requestData ? requestData.page + 1 : exportQuery.page) || 1,
      'order[id]': 'DESC',
      'date[]': new Moment(date).format(DATE_FORMAT),
      'date[_operator]': 'eq',
      selectInBrands: selectInBrands.length
        ? selectInBrands.map(brand => brand.value).filter(val => val !== '*')
        : [],
    };
    setExportQuery(query);

    get('/overproductions', {
      ...query,
    }).then(res => {
      const newOverProductions = res['hydra:member'];
      const modified = newOverProductions.map(item => ({
        ...item,
        mealType: item.mealType['@id'],
        size: item.size['@id'],
        temporaryId:
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15),
        foodCostTemp: item.dishSize?.price
          ? (item.dishSize.price * item.total).toFixed(2)
          : 0,
        hasChanged: false,
      }));

      const unSavedOverProductions = rowToBeSaved
        ? overProductions.filter(
            overProduction =>
              !overProduction['@id'] &&
              overProduction.temporaryId !== rowToBeSaved.temporaryId
          )
        : [];

      setIsTableLoading(false);
      setOverProductions([...unSavedOverProductions, ...modified]);
    });
  };

  const fetchMenuPlanner = async () => {
    const response = await get('/menu-planners', {
      pagination: false,
      'date[after]': new Moment(date).format(DATE_FORMAT),
      'date[before]': new Moment(date).format(DATE_FORMAT),
      selectInBrands: userBrands.map(userBrand => userBrand['@id']),
    });

    setMenuPlanners(response['hydra:member']);

    return response;
  };

  const fetchMealTypesFromConfig = async () => {
    const response = await get('/meal-types', {
      pagination: false,
      selectInBrands: userBrands.map(userBrand => userBrand['@id']),
    });

    setMealTypesFromConfig(response['hydra:member']);

    return response;
  };

  const transformMenuPlannerData = data => {
    const dishes = data
      .filter(item => !item.draft)
      .map(item => item && item.dish);
    const uniqueDishes = [];
    dishes.forEach(dish => {
      if (dish && !uniqueDishes.some(uniqueDish => uniqueDish.id === dish.id)) {
        uniqueDishes.push(dish);
      }
    });

    uniqueDishes.forEach(uniqueDish => {
      let mealTypes = [];
      let brands = [];
      let sizes = [];
      data.forEach(item => {
        if (item && uniqueDish['@id'] === item.dish['@id']) {
          if (!mealTypes.includes(item.mealType)) {
            mealTypes.push(item.mealType);
          }
          if (!brands.includes(item.brand['@id'])) {
            brands.push(item.brand['@id']);
          }

          item.sizes &&
            Object.values(item.sizes).forEach(size => {
              if (size && !sizes.includes(size.size)) {
                sizes.push(size.size);
              }
            });
        }
      });

      uniqueDish.brands = brands.map(brand =>
        userBrands.find(userBrand => userBrand['@id'] === brand)
      );
      uniqueDish.mealTypes = sortByKey(
        mealTypes.map(type =>
          mealTypesFromConfig.find(mealType => mealType['@id'] === type)
        ),
        'position'
      );

      return uniqueDish;
    });

    return uniqueDishes;
  };

  const menuPlannerOptions = transformMenuPlannerData(menuPlanner);

  const changeRow = (row, ev, name) => {
    const findBy = row.temporaryId ? row.temporaryId : row['@id'];
    const key = row.temporaryId ? 'temporaryId' : '@id';

    const newOverProductions = [...overProductions];
    const rowToChange = newOverProductions.find(
      overProduction => overProduction[key] === findBy
    );
    rowToChange[name] = ev.target.value;
    rowToChange.hasChanged = true;
    setOverProductions(newOverProductions);
  };

  const changeSubBrand = (row, { target: { value } }) => {
    const findBy = row.temporaryId ? row.temporaryId : row['@id'];
    const key = row.temporaryId ? 'temporaryId' : '@id';

    const newOverProductions = [...overProductions];
    const rowToChange = newOverProductions.find(
      overProduction => overProduction[key] === findBy
    );
    rowToChange.subBrand['@id'] = value;
    rowToChange.hasChanged = true;

    setOverProductions(newOverProductions);
  };

  const validateRow = row => {
    if (row.total < 0) {
      openToast({
        messages: [
          t('common.overproduction.qtyAdditionalOrdersCantBeNegative'),
        ],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });

      return false;
    }

    return row.mealType && row.size && row.subBrand['@id'] && row.total;
  };

  const changeMealType = (row, { target: { name, value } }) => {
    const findBy = row.temporaryId ? row.temporaryId : row['@id'];
    const key = row.temporaryId ? 'temporaryId' : '@id';

    const newOverProductions = [...overProductions];

    const rowToChange = newOverProductions.find(
      overProduction => overProduction[key] === findBy
    );

    const currentMealType = rowToChange.dishSize.dish.types.find(
      type => type?.['@id'] === value
    );

    rowToChange[name] = value;
    rowToChange.size = null;
    rowToChange.hasChanged = true;

    if (rowToChange.subBrand.brand['@id'] !== currentMealType.brand['@id']) {
      rowToChange.subBrand['@id'] = '';
      rowToChange.subBrand.brand = currentMealType.brand;
    }

    setOverProductions(newOverProductions);
  };

  const changeAmountWithFoodCost = (row, ev) => {
    const findBy = row.temporaryId ? row.temporaryId : row['@id'];
    const key = row.temporaryId ? 'temporaryId' : '@id';

    const newOverProductions = [...overProductions];

    const rowToChange = newOverProductions.find(
      overProduction => overProduction[key] === findBy
    );

    const price =
      row.dishSize.price !== null
        ? row.dishSize.price
        : row.mealType && row.size
        ? row.dishSize.prices[`${row.mealType}_${row.size}`]
        : 0;

    rowToChange.total = (+ev.target.value / price).toFixed(2);
    rowToChange.hasChanged = true;
    setOverProductions(newOverProductions);
  };

  const changeFoodCostWithAmount = (row, ev) => {
    const findBy = row.temporaryId ? row.temporaryId : row['@id'];
    const key = row.temporaryId ? 'temporaryId' : '@id';

    const newOverProductions = [...overProductions];

    const rowToChange = newOverProductions.find(
      overProduction => overProduction[key] === findBy
    );

    const price =
      row.dishSize.price !== null
        ? row.dishSize.price
        : row.mealType && row.size
        ? row.dishSize.prices[`${row.mealType}_${row.size}`]
        : 0;

    rowToChange.foodCostTemp = (+ev.target.value * price).toFixed(2);
    rowToChange.hasChanged = true;
    setOverProductions(newOverProductions);
  };

  const renderAlert = (onConfirm, onCancel) => {
    const alert = (
      <SweetAlert
        warning
        title={t('common.overproduction.areYouSure')}
        onConfirm={() => onConfirm()}
        onCancel={() => onCancel()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        confirmBtnText={t('common.shared.yes')}
        cancelBtnText={t('common.shared.no')}
        showCancel
      />
    );

    setAlert(alert);
  };

  const saveRow = row => {
    if (validateRow(row)) {
      const data = { ...row };
      data.dishSize = data.dishSize.dish.sizes[`${data.mealType}_${data.size}`];
      data.subBrand = data.subBrand['@id'];
      data.total = parseInt(data.total);

      const action = data['@id'] ? put : post;
      const endpoint = data['@id'] ? data['@id'] : '/overproductions';
      action(endpoint, data).then(
        () => {
          fetchOverProductionsOnFetchData({ rowToBeSaved: row });
          getTotalItems(pageSize);
          openToast({
            messages: [
              action === put
                ? t('common.overproduction.changedAdditionalOrder')
                : t('common.overproduction.addedAdditionalOrder'),
            ],
            type: 'success',
            autoHideDuration: TOAST_DURATIONS.SM,
          });
        },
        error => {
          openToast({
            messages: [t('common.overproduction.failAddAdditionalOrder')],
            type: 'error',
            autoHideDuration: TOAST_DURATIONS.SM,
          });
        }
      );
    }
  };

  const getExportButtonConfig = () => {
    const namesForExport = translateNamesForExport(t);
    return [
      {
        label: 'XLSX',
        format: 'xlsx',
        endpoint: 'overproductions',
        fileName: (url, date) => {
          return namesForExport[url] ? namesForExport[url] + date : url + date;
        },
      },
      {
        label: 'CSV',
        format: 'csv',
        endpoint: 'overproductions',
        fileName: (url, date) => {
          return namesForExport[url] ? namesForExport[url] + date : url + date;
        },
      },
    ];
  };

  const exportData = ({ format, fileName, endpoint, manipulateQuery }) => {
    setIsExporting(true);

    const date = moment().format('DD-MM-YYYY_HH-mm');
    const exportedName =
      typeof fileName === 'string' ? fileName : fileName(endpoint, date);

    const params =
      typeof manipulateQuery === 'function'
        ? manipulateQuery({ ...exportQuery })
        : exportQuery;

    axios
      .get(`${endpoint}.${format}`, {
        responseType: 'blob',
        params: params,
      })
      .then(
        response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${exportedName}.${format}`);
          document.body.appendChild(link);
          link.click();
          setIsExporting(false);
        },
        error => {
          setIsExporting(false);
          return openToast({
            messages: [
              t(
                'errors.dataExportFailed',
                'Nie udało się wyeksportować danych'
              ),
            ],
            type: 'error',
            autoHideDuration: TOAST_DURATIONS.SM,
          });
        }
      );
  };

  const overProductionsToSave = overProductions.filter(
    overProd => overProd.temporaryId && validateRow(overProd)
  );
  const saveAllRows = () => {
    overProductionsToSave.length && setIsFetching(true);
    setIsTableLoading(true);

    return Promise.all(
      overProductionsToSave.map(overProd => {
        const row = { ...overProd };
        row.dishSize = row.dishSize.dish.sizes[`${row.mealType}_${row.size}`];
        row.subBrand = row.subBrand['@id'];
        row.total = parseInt(row.total);

        const action = row['@id'] ? put : post;
        const endpoint = row['@id'] ? row['@id'] : '/overproductions';
        return action(endpoint, row).then(
          () => {
            fetchOverProductionsOnFetchData({});
            getTotalItems(pageSize);
            openToast({
              messages: [t('common.overproduction.savedAllAdditionalOrders')],
              type: 'success',
              autoHideDuration: TOAST_DURATIONS.SM,
            });
            setIsFetching(false);
            setIsTableLoading(false);
          },
          error => {
            openToast({
              messages: [t('common.overproduction.failSaveAdditionalOrder')],
              type: 'error',
              autoHideDuration: TOAST_DURATIONS.SM,
            });
            setIsFetching(false);
            setIsTableLoading(false);
          }
        );
      })
    );
  };

  const columns = [
    {
      title: 'ID',
      accessor: 'id',
      name: 'id',
      filterable: false,
      sortable: false,
      width: 50,
    },
    {
      title: t('columns.name'),
      name: 'dishSize.name',
      accessor: ({ ...row }) => {
        const { dishSize } = row;
        if (isEmpty(dishSize)) return null;
        return (
          <div>
            <NavLink
              to={`/admin/meals/edit/${getNumberFromString(
                dishSize.dish['@id']
              )}?selectedBrand=${selectedBrand}`}
              target={'_blank'}
            >
              <h4>{dishSize.dish.nameForClient}</h4>
            </NavLink>
            <h6>
              {dishSize.dish.brands &&
                dishSize.dish.brands.map(({ name }) => name).join(', ')}
            </h6>
          </div>
        );
      },
      filterable: false,
      sortable: false,
      width: 250,
    },
    {
      title: t('columns.mealType'),
      name: 'mealType',
      accessor: ({ mealType, types, ...row }) => {
        if (isEmpty(row?.dishSize?.dish)) return null;
        return (
          <SelectInput
            classes={classes}
            mapBy="name"
            trackBy="@id"
            name="mealType"
            customLabel={option => `${option.name} (${option.brand.name})`}
            value={mealType}
            disabled={row['@id'] && !isGranted(ROLE_EDIT_OVERPRODUCTION)}
            options={sortByGroupedBrandPosition(row.dishSize.dish.types)}
            handleChange={e => changeMealType(row, e)}
            id="overProductionMealType"
            size={12}
          />
        );
      },
      filterable: false,
      sortable: false,
      width: 150,
    },
    {
      title: t('columns.size'),
      name: 'size',
      filterable: false,
      accessor: ({ size, ...row }) => {
        if (isEmpty(row?.dishSize?.dish)) return null;
        const selectedMealType = row.dishSize.dish.types.find(
          type => type?.['@id'] === row.mealType
        );

        const selectedMealTypeFromConfig =
          selectedMealType &&
          mealTypesFromConfig.find(
            mealType => mealType['@id'] === selectedMealType['@id']
          );

        const disabledSizes =
          selectedMealType &&
          selectedMealTypeFromConfig?.sizes
            .filter(({ size }) => {
              const currentKey = `${selectedMealTypeFromConfig['@id']}_${size['@id']}`;

              return !Object.keys(row.dishSize.dish.sizes).includes(currentKey);
            })
            .map(({ size }) => size);

        return (
          <div
            onClick={() =>
              !selectedMealType &&
              openToast({
                messages: [t('common.overproduction.firstSelectMealType')],
                type: 'info',
                autoHideDuration: TOAST_DURATIONS.SM,
              })
            }
          >
            <SelectInput
              classes={classes}
              customLabel={option => {
                const mealTypeSize = selectedMealTypeFromConfig.sizes.find(
                  size => size.size['@id'] === option['@id']
                );
                return `${mealTypeSize.size.name} - ${mealTypeSize.calories} kcal`;
              }}
              mapBy="name"
              trackBy="@id"
              name="size"
              value={size}
              disabledMenuItems={disabledSizes}
              options={
                selectedMealType && selectedMealTypeFromConfig
                  ? sortByNestedKey(
                      selectedMealTypeFromConfig.sizes,
                      'size',
                      'position'
                    ).map(mealTypeSize => mealTypeSize.size)
                  : []
              }
              handleChange={async e => {
                const newSize = e.target.value;
                const mealTypeSizeKey = `${row.mealType}_${newSize}`;
                const dishSize = row.dishSize.dish.sizes[mealTypeSizeKey];
                const brandIri = row.subBrand.brand['@id'];
                const dishPrice = await getPriceForDishSize(dishSize, brandIri);
                const updatedRow = row;
                updatedRow.dishSize.price = dishPrice;
                changeRow(updatedRow, e, 'size');
                changeFoodCostWithAmount(updatedRow, {
                  target: { value: updatedRow.total || 0 },
                });
              }}
              disabled={
                !selectedMealType ||
                (row['@id'] && !isGranted(ROLE_EDIT_OVERPRODUCTION))
              }
              id="overProductionSize"
              size={12}
            />
          </div>
        );
      },
      sortable: false,
      width: 150,
    },
    {
      title: t('columns.qty'),
      name: 'total',
      accessor: ({ total, ...row }) => {
        return (
          <div style={{ paddingTop: '9px' }}>
            <CustomInput
              formControlProps={{ fullWidth: true }}
              inputProps={{
                disabled: row['@id'] && !isGranted(ROLE_EDIT_OVERPRODUCTION),
                type: 'number',
                placeholder: t('common.overproduction.typeValue'),
                name: 'total',
                value: total,
                onChange: ev => {
                  changeRow(row, ev, 'total');
                  changeFoodCostWithAmount(row, ev);
                },
              }}
            />
          </div>
        );
      },
      filterable: false,
      sortable: false,
      width: 100,
    },
    {
      title: t('columns.foodcost', {
        defaultValue: 'Foodcost ({{currency}})',
        replace: {
          currency: currency,
        },
      }),
      name: 'foodcost',
      accessor: row => {
        return (
          <div style={{ paddingTop: '9px' }}>
            <CustomInput
              formControlProps={{ fullWidth: true }}
              inputProps={{
                disabled: row['@id'] && !isGranted(ROLE_EDIT_OVERPRODUCTION),
                type: 'number',
                placeholder: t('common.overproduction.typeValue'),
                name: 'total',
                value: row.foodCostTemp,
                onChange: ev => {
                  changeRow(row, ev, 'foodCostTemp');
                  changeAmountWithFoodCost(row, ev);
                },
              }}
            />
          </div>
        );
      },
      filterable: false,
      sortable: false,
      width: 100,
    },
    {
      title: t('columns.subbrand'),
      name: 'subBrand',
      accessor: ({ subBrand, types, ...row }) => {
        const subBrandsOptions =
          [
            ...subBrands.filter(
              subBrandOption => subBrandOption.brand === subBrand.brand['@id']
            ),
          ] || [];
        if (isEmpty(row?.dishSize?.dish)) return null;
        const selectedMealType = row.dishSize.dish.types.find(
          type => type?.['@id'] === row.mealType
        );

        return (
          <div
            onClick={() =>
              !selectedMealType &&
              openToast({
                messages: [t('common.overproduction.firstSelectMealType')],
                type: 'info',
                autoHideDuration: TOAST_DURATIONS.SM,
              })
            }
          >
            <SelectInput
              classes={classes}
              mapBy="name"
              trackBy="@id"
              name="subBrand"
              disabled={
                !selectedMealType ||
                (row['@id'] && !isGranted(ROLE_EDIT_OVERPRODUCTION))
              }
              value={subBrand['@id']}
              options={subBrandsOptions}
              handleChange={e => changeSubBrand(row, e)}
              id="subBrand"
              size={12}
            />
          </div>
        );
      },
      filterable: false,
      sortable: false,
      width: 300,
    },
    {
      title: '',
      name: 'action',
      accessor: row => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexWrap: 'wrap',
            }}
          >
            {isGranted(ROLE_CREATE_OVERPRODUCTION) && (
              <Button
                simple
                color={'info'}
                onClick={() => {
                  const findBy = row.temporaryId ? row.temporaryId : row['@id'];
                  const key = row.temporaryId ? 'temporaryId' : '@id';

                  const overProductionsCopy = [...overProductions];

                  const rowToAdd = {
                    ...overProductionsCopy.find(
                      overProduction => overProduction[key] === findBy
                    ),
                  };
                  rowToAdd.temporaryId =
                    Math.random().toString(36).substring(2, 15) +
                    Math.random().toString(36).substring(2, 15);
                  rowToAdd['@id'] = null;
                  rowToAdd.id = null;

                  const newOverProductions = [rowToAdd, ...overProductions];
                  setOverProductions(newOverProductions);
                }}
              >
                <div>
                  <span style={{ marginRight: '5px' }}>
                    {t('common.shared.duplicate')}
                  </span>
                  <AddCircle />
                </div>
              </Button>
            )}
            {isGranted(ROLE_REMOVE_OVERPRODUCTION) && (
              <Button
                simple
                color={'danger'}
                onClick={() => {
                  const onConfirm = () => {
                    const findBy = row.temporaryId
                      ? row.temporaryId
                      : row['@id'];
                    const key = row.temporaryId ? 'temporaryId' : '@id';

                    const filteredOverProductions = overProductions.filter(
                      overProduction => overProduction[key] !== findBy
                    );
                    row['@id'] && remove(row['@id']);
                    setOverProductions(filteredOverProductions);
                    setAlert(null);
                  };
                  const onCancel = () => setAlert(null);
                  !row['@id'] ? onConfirm() : renderAlert(onConfirm, onCancel);
                }}
              >
                <span style={{ marginRight: '5px' }}>
                  {t('common.shared.delete')}
                </span>
                <RemoveCircle />
              </Button>
            )}
            {isGranted(
              row['@id'] ? ROLE_EDIT_OVERPRODUCTION : ROLE_CREATE_OVERPRODUCTION
            ) && (
              <Button
                simple
                color={'success'}
                disabled={!validateRow(row) || (row['@id'] && !row.hasChanged)}
                onClick={() => saveRow(row)}
              >
                <div>
                  <span style={{ marginRight: '5px' }}>
                    {t('common.shared.save')}
                  </span>
                  <CheckBox />
                </div>
              </Button>
            )}
          </div>
        );
      },
      filterable: false,
      sortable: false,
    },
  ];

  let columnConfig = columns.map(column => {
    return {
      ...column,
      id: column.name,
      Header: column.title,
      accessor: column.accessor,
      sortable: column.sortable,
    };
  });

  return isGranted(roles.ROLE_SHOW_OVERPRODUCTION) ? (
    <AdminTable title={t('list.additionalOrders')} isMarginLeft={true}>
      <GridContainer>
        {alert}
        <DialogLoader
          loaderState={isFetching || isExporting}
          text={t('common.shared.isLoading')}
        />
        <ChooseFromMenuPlanningModal
          classes={classes}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          date={date}
          userBrands={userBrands}
          selectInBrands={selectInBrands}
          menuPlannerOptions={menuPlannerOptions}
          setOverProductions={setOverProductions}
          overProductions={overProductions}
          mealTypesFromConfig={mealTypesFromConfig}
          transformMenuPlannerData={transformMenuPlannerData}
        />
        <GridItem md={3}>
          <FormLabel
            className={classes.labelHorizontal}
            style={{ marginBottom: '5px' }}
          >
            {t('common.shared.selectDate')}
          </FormLabel>
          <FormControl fullWidth>
            <Datetime
              isValidDate={isDatepickerValidDay}
              timeFormat={false}
              closeOnSelect={true}
              value={date}
              onChange={ev => {
                setDate(ev);
                setOverProductions([]);
              }}
              inputProps={{
                readOnly: true,
              }}
            />
          </FormControl>
        </GridItem>
        <GridItem md={12}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  display: 'flex',
                  transition: '0.5s all',
                  marginLeft: '3px',
                  marginRight: '10px',
                }}
                data-cy="__export_dropdown"
              >
                <CustomDropdown
                  hoverColor="info"
                  buttonText={t('dataGrid.export')}
                  buttonProps={{
                    fullWidth: true,
                  }}
                  dropdownHeader={t('dataGrid.selectFormat')}
                  dropdownList={getExportButtonConfig().map(conf => ({
                    handleClick: () =>
                      exportData({
                        format: conf.format,
                        fileName: conf.fileName,
                        endpoint: conf.endpoint,
                        manipulateQuery: conf.manipulateQuery,
                      }),
                    optionText: conf.label,
                  }))}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {!isFetching &&
                  `${overProductions.length} ${t('dataGrid.pageOf')} ${
                    overProductions.length >= currentTotalItems
                      ? overProductions.length
                      : currentTotalItems
                  } ${t('dataGrid.results')}`}
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {isGranted(ROLE_CREATE_OVERPRODUCTION) && (
                <div
                  style={{ marginRight: '5px' }}
                  onClick={() =>
                    !selectInBrands.length &&
                    openToast({
                      messages: [t('dataGrid.firstSelectBrands')],
                      type: 'info',
                      autoHideDuration: TOAST_DURATIONS.SM,
                    })
                  }
                >
                  <Button
                    disabled={!selectInBrands.length}
                    color={'primary'}
                    onClick={() => setModalOpen(true)}
                  >
                    {t('common.shared.additionalOrder')} +
                  </Button>
                </div>
              )}
              {isGranted(ROLE_CREATE_OVERPRODUCTION) && (
                <Button
                  color={'success'}
                  onClick={saveAllRows}
                  disabled={!overProductionsToSave.length}
                >
                  {t('common.shared.saveAll')}
                </Button>
              )}
            </div>
          </div>
        </GridItem>
        <GridItem md={12}>
          <ReactTable
            manual
            loading={isTableLoading}
            data={overProductions}
            pageSizeOptions={[1, 5, 10, 20, 50, 100]}
            columns={columnConfig}
            defaultPageSize={defaultPageSize}
            pageSize={pageSize}
            onPageSizeChange={pageSize => {
              setPageSize(pageSize);
              getTotalItems(pageSize);
            }}
            showPaginationBottom={false}
            pages={pages}
            onFetchData={(requestData, table) =>
              fetchOverProductionsOnFetchData({ requestData, table })
            }
            showPaginationTop
            className="-striped -highlight"
            previousText={t('dataGrid.prevPage')}
            nextText={t('dataGrid.nextPage')}
            loadingText={t('dataGrid.loading')}
            noDataText={t('dataGrid.notFound')}
            pageText={t('dataGrid.page')}
            ofText={t('dataGrid.pageOf')}
            rowsText={t('dataGrid.results')}
          />
        </GridItem>
      </GridContainer>
    </AdminTable>
  ) : (
    <AccessDenied />
  );
};

const combinedStyles = combineStyles(
  extendedFormsStyle,
  buttonsStyle,
  sweetAlertStyle
);

const mapStateToProps = state => ({
  userBrands: state.Auth.user.brands,
  selectedBrand: state.Auth.selectedBrand,
  mealSizes: state.MealSizes.mealSizes,
  currency: state.Brands.brand.multinational.currency,
});
//
// const mapDispatchToProps = {
//   fetchMealTypes,
// }

const enhance = compose(
  connect(mapStateToProps, null),
  withStyles(combinedStyles),
  withToast
);

export default enhance(Overproduction);
